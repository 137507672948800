import React from 'react'
import { Link } from 'gatsby'

import Layout from '../containers/layout'
import SEO from '../components/seo'

import styles from '../styles/404.css'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
	<div class="root">
	    <h1>NOT FOUND</h1>
	    <p>Sorry for the inconvenience, the page you are looking for does not exist.</p>
		<p><Link to="/">Return to home page</Link></p>
	</div>
  </Layout>
)

export default NotFoundPage
